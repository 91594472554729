@import "./service/reset";
//@import "./service/normalize";
@import "src/styles/abstracts/_mixins.scss";
@import "./variables/index";
@import "classes.scss";
//@import "antd.css";

// https://github.com/facebook/create-react-app/issues/11771
body > iframe[style*="2147483647"]:not([id="webpack-dev-server-client-overlay"]) {
  //display: none;
}

* {
  font-family: -apple-system, BlinkMacSystemFont, Inter, Segoe UI, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
  Helvetica Neue, sans-serif;
}

// App styles
//@include md {
//  * {
//    scrollbar-color: var(--color-primary-weak) var(--color-layout-fill-base);
//    scrollbar-width: thin;
//  }
//
//  ::-webkit-scrollbar {
//    width: 4px;
//    height: 4px;
//    border-radius: 4px;
//    //width: 10px;
//    //height: 4px;
//    //background-color: var(--color-layout-container);
//    background-color: inherit;
//  }
//
//  ::-webkit-scrollbar-thumb {
//    border-radius: 4px;
//    background-color: var(--color-primary-weak);
//    //background-color: var(--color-primary-base);
//    //border-left: 6px solid rgba(0, 0, 0, 0);
//    //background-clip: padding-box;
//  }
//}

@include md {
  * {
    scrollbar-color: var(--color-srf-accent) var(--color-srf-primary);
    scrollbar-width: thin;
  }
  ::-webkit-scrollbar {
    width: 10px;
    height: 4px;
    background-color: inherit;
  }
  ::-webkit-scrollbar-thumb {
    background-color: var(--color-srf-accent);
    border-left: 6px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
  }
}

//@include md {
//  * {
//    scrollbar-color: var(--color-primary-base) var(--color-layout-fill-base);
//    scrollbar-width: thin;
//  }
//
//  //::-webkit-scrollbar {
//  //  width: 10px;
//  //  height: 4px;
//  //  background-color: inherit;
//  //}
//  //
//  //::-webkit-scrollbar-thumb {
//  //  background-color: var(--color-primary-base);
//  //  border-left: 6px solid rgba(0, 0, 0, 0);
//  //  background-clip: padding-box;
//  //}
//
//  ::-webkit-scrollbar {
//    width: 12px;
//    height: 4px;
//  }
//
//  ::-webkit-scrollbar-thumb {
//    border: 4px solid rgba(0, 0, 0, 0);
//    background-clip: padding-box;
//    border-radius: 9999px;
//    background-color: var(--color-primary-base);
//  }
//}

.app-layout__wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: var(--color-bg-primary) !important;

  @media print {
    background: transparent!important;
    position: static;

    .app-content__wrapper_regulation {
      > .d-stack-row.ml-n2.my-n2.full-height {
        overflow: visible !important;
        width: auto !important;
        height: auto !important;
      }
    }

    .regulation-sidebar_hidden + div {
      overflow: visible !important;
    }
  }


}


.ant-layout {
  background: transparent;
}

.app-content__wrapper {
  padding: var(--indent-md);
  display: flex;
  flex-direction: column;
  //box-shadow: inset var(--shadow-down-sm);
  border-top: 1px solid var(--color-brdr-inverse);
  border-right: 1px solid var(--color-brdr-inverse);

  //&_communication, &_regulation {
  //  border-top-color: var(--color-brdr-light);
  //  border-right-color: var(--color-brdr-light);
  //}

  &_regulation {
    background: var(--color-srf-primary);
  }

  &_settings {
    .page-content-sheet-tabs {
      padding: 16px 8px 0;
      background: var(--color-srf-primary);
      border-radius: 0;
      margin-bottom: 16px!important;
    }

    .page-content__wrapper {
      padding: 16px;
      background: var(--color-srf-primary);
    }

  }

}

#notification__wrapper {
  position: fixed;
  right: 0;
  bottom: 8px;
  top: 49px;
  //background-color: red;
  width: 24px;
  padding: 0 16px;
  display: flex;
  flex-direction: column-reverse;
  pointer-events: none;

  #notification__inner {
    display: flex;
    width: 5px;
    height: 32px;
    flex-direction: column-reverse;
    background-color: green;
  }
}

// AntD styles

//.ant-dropdown {
//  box-shadow: var(--shadow-down-sm) !important;
//  .ant-dropdown-menu-item:hover:not(.ant-dropdown-menu-item-disabled) {
//    color: var(--color-primary-base);
//  }
//}

.ant-notification-notice {
  box-shadow: var(--shadow-down-lg) !important;
}

.ant-menu {
  li {
    //height: 28px !important;
    //line-height: 28px !important;
  }
}

.ant-avatar {
  flex-shrink: 0 !important;
  flex-grow: 0 !important;
  //box-sizing: content-box;
}

.ant-form-item {
  .ant-form-item-label {
    padding-bottom: var(--indent-sm) !important;

    label {
      color: var(--color-inp-label-default) !important;
      font-size: 15px !important;
      line-height: 1em !important;
    }
  }

  &:not(.ant-form-item-with-help):not(.mb-0) {
    margin-bottom: var(--indent-md) !important;
  }

  &.ant-form-item-with-help {
    margin-bottom: 8px !important;
  }

  .ant-form-item-margin-offset {
    //margin-bottom: -24px !important;
  }
}



.ant-tabs-tab {
  .ant-tabs-tab-btn {
    text-transform: uppercase;
    font-weight: 500;
  }

  &:not(.ant-tabs-tab-active) {
    .ant-tabs-tab-btn {
      color: var(--color-txt-secondary) !important;
    }

    &:hover {
      .ant-tabs-tab-btn {
        color: var(--color-txt-active) !important;
      }
    }
  }
}

.ant-tabs-tab + .ant-tabs-tab {
  margin: 0 0 0 8px !important;
}

.ant-notification-notice-description {
  white-space: pre-line !important;
  opacity: 0.85;
}

//.ant-input:focus {
//  border-color: var(--color-primary-base);
//  outline: 0;
//  box-shadow: 0 0 0 2px rgba(var(--color-primary-rgb), 0.2);
//}

.ant-dropdown {
  //box-shadow: var(--shadow-down-md) !important;
}

.ant-menu {
  li {
    //height: 28px !important;
    //
    //height: auto !important;
    //line-height: 28px !important;
  }
}

.ant-notification-notice-message {
  margin-bottom: 0 !important;
}

.ant-form-item-margin-offset {
  //margin-bottom: -24px !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  //-webkit-box-shadow: 0 0 0 50px white inset;
  -webkit-background-clip: text !important;
  background-clip: text !important;
  -webkit-text-fill-color: black !important;
}
@media screen {
  //html[data-app-theme-id="1"] {
  //  .app-layout__wrapper,
  //  .ant-layout {
  //    background: #090909 !important;
  //  }
  //}

  html[data-app-theme-id="1"] {
    input[type="number"] {
      color-scheme: dark;
    }
  }

  html[data-app-theme-id="1"] {
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      //-webkit-box-shadow: 0 0 0 50px #141414 inset;
      //color-scheme: dark;
      -webkit-background-clip: text !important;
      background-clip: text !important;
      -webkit-text-fill-color: white !important;
    }
  }
  //
  //html[data-app-theme-id="1"] {
  //  .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover {
  //    &::before {
  //      background: var(--color-layout-divider) !important;
  //    }
  //  }
  //
  //  .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover-end {
  //    &::before {
  //      background: var(--color-layout-divider) !important;
  //    }
  //  }
  //
  //  .ant-picker-cell-inner {
  //    &::after {
  //      background: var(--color-layout-divider) !important;
  //    }
  //  }
  //}
}

.form-item-error {
  position: absolute;
  top: 100%;
  left: 0;
}

.form-row {
  & + & {
    margin-top: 16px;
  }

  &_flex {
    display: flex;
    align-items: center;
    gap: 16px;
  }
}
.ant-form-item {
  &.form-item {
    &_bold {
      .ant-form-item-label {
        font-weight: bold;
      }
    }


    @for $i from 0 to 64 {
      // Margin negative
      &.form-item_mb#{$i} {
        margin-bottom: $i * $indent-sm !important;
      }
    }

  }
}
//setTimeout(()=> {debugger},5000)

//:where(.css-dev-only-do-not-override-1liqky).ant-btn-default {
//  background: var(--color-layout-silver);
//}

.ant-notification {
  .ant-notification-notice-wrapper {
    background: var(--color-srf-primary);

    .ant-notification-notice {
      padding-right: 50px;
    }

    .ant-notification-notice-message {
      color: var(--color-txt-primary);
    }

    .ant-notification-notice-icon-success.anticon {
      color: var(--color-icn-success);
    }

    .ant-notification-notice-icon-error.anticon {
      color: var(--color-icn-violation);
    }

    .ant-notification-notice-icon-warning.anticon {
      color: var(--color-icn-warning);
    }

    .ant-notification-notice-icon-info.anticon {
      color: var(--color-icn-accent);
    }

  }
}


.ant-segmented {
  background: var(--color-srf-secondary);

  .ant-segmented-item {
    color: var(--color-txt-secondary);
  }

  .ant-segmented-item-selected {
    color: var(--color-txt-primary);
    background: var(--color-srf-primary);
  }

  .ant-segmented-item-label {
    min-height: 29px;
    line-height: 29px;
  }
}


.ant-tag-error {
  color: var(--color-tag-txt-red);
  background: var(--color-tag-bg-red);
  border-color: var(--color-tag-brdr-red);
}

.ant-tag-warning {
  color: var(--color-tag-txt-orange);
  background: var(--color-tag-bg-orange);
  border-color: var(--color-tag-brdr-orange);
}

.ant-tooltip {
  .ant-tooltip-inner {
    background: var(--color-srf-tooltip);
    color: var(--color-txt-alwayswhite);
  }

  .ant-tooltip-arrow {
    &:before {
      background: var(--color-srf-tooltip);
    }
  }

}

.ant-modal {
  color: var(--color-txt-primary);

  .ant-modal-content {
    background: var(--color-srf-primary);
  }

  .ant-modal-header {
    background: var(--color-srf-primary);
    color: var(color-text-primary);
  }
}

.ant-input {
  background: transparent;
  color: var(--color-txt-primary);
  border-color: var(--color-inp-border-default);

  &::placeholder {
    color: var(--color-inp-text-placeholder);
  }

  &:hover {
    border-color: var(--color-inp-border-hover);
  }

  &:focus, &:active {
    border-color: var(--color-inp-border-focus);
  }

  &.ant-input-disabled,
  &.ant-input[disabled] {
    border-color: var(--color-inp-fill-disabled);
    background: var(--color-inp-fill-disabled);
    color: var(--color-inp-text-placeholder);
  }

}



.ant-picker {
  background: transparent;
  color: var(--color-txt-primary);
  border-color: var(--color-inp-border-default);
  transition: color .2s ease-in, border-color .2s ease-in;

  &:hover {
    border-color: var(--color-inp-border-hover);
  }

  &:focus {
    border-color: var(--color-inp-border-focus);
  }

  &::placeholder {
    color: var(--color-inp-text-placeholder);
  }

  .ant-picker-clear, .ant-picker-suffix {
    color: var(--color-inp-icon) !important;
  }

  .ant-picker-clear {
    background: transparent;
  }
}

.ant-picker-dropdown {

  .ant-picker-panel-container {
    background: var(--color-srf-menu);
  }

  .ant-picker-header {
    color: var(--color-txt-primary);

    button {
      color: var(--color-inp-icon);

      &:hover {
        color: var(--color-icn-accent);
      }
    }
  }

  .ant-picker-header-view {
    button {
      color: var(--color-txt-primary);

      &:hover {
        color: var(--color-icn-accent);
      }

    }
  }

  .ant-picker-cell {
    color: var(--color-txt-secondary);
  }

  .ant-picker-cell-in-view {
    color: var(--color-txt-primary);
  }

  .ant-picker-time-panel-column {
    color: var(--color-txt-primary);

    >li.ant-picker-time-panel-cell-selected {
      .ant-picker-time-panel-cell-inner {
        color: var(--color-txt-alwayswhite);
        background: var(--color-srf-accent);
      }
    }

  }

  .ant-picker-cell:hover:not(.ant-picker-cell-in-view),
  .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) {
    .ant-picker-cell-inner {
      background: var(--color-srf-menu-hover)
    }
  }

  .ant-picker-cell-in-view.ant-picker-cell-selected,
  .ant-picker-cell-in-view.ant-picker-cell-range-start,
  .ant-picker-cell-in-view.ant-picker-cell-range-end {
    .ant-picker-cell-inner {
      color: var(--color-txt-alwayswhite);
      background: var(--color-srf-accent);
    }
  }

}

.ant-badge {

  .ant-badge-count {
    background: var(--color-icn-violation);
    color: var(--color-txt-inverse);
  }
}

.ant-input-group {
  .ant-input-group-addon {
    background: transparent;
    border-color: var(--color-inp-border-default);
  }
}

.ant-btn-link {
  color: var(--color-txt-active);
}

.ant-btn-primary {
  background: var(--color-btn-primary-default);
  color: var(--color-btn-texticon-primary);

  &:hover {
    background: var(--color-btn-primary-hover);
  }

  &.ant-btn-background-ghost {
    border-color: var(--color-btn-primary-default);
    background: transparent;
    color: var(--color-btn-primary-default);
  }

  &:disabled, &.ant-btn-disabled {
    pointer-events: none;
    background: var(--color-btn-primary-fill-disabled);
    border-color: var(--color-btn-primary-fill-disabled);
    color: var(--color-btn-texticon-disabled)
  }
}

.ant-btn-default {
  background: transparent;
  border-color: var(--color-brdr-default);
  color: var(--color-txt-primary);
}

.ant-btn-text {
  &.ant-btn-dangerous {
    color: var(--color-txt-error);

    &:not(:disabled):not(.ant-btn-disabled):hover {
      color: var(--color-txt-error);
      //background: var(--color-btn-destructive-hover);
    }
  }
}

.ant-select {
  &:not(.ant-select-customize-input) {
    .ant-select-selector {
      border-color: var(--color-inp-border-default);
      background: transparent;
    }
  }
  color: var(--color-txt-primary);

  .ant-select-selection-placeholder {
    color: var(--color-inp-text-placeholder);
  }

  .ant-select-arrow {
    color: var(--color-inp-icon);
  }

  .ant-select-clear {
    color: var(--color-inp-icon) !important;
    background: transparent;
  }
}

.ant-select-disabled {
  &.ant-select:not(.ant-select-customize-input) {
    .ant-select-selector {
      border-color: var(--color-inp-fill-disabled);
      background: var(--color-inp-fill-disabled);
      color: var(--color-inp-text-placeholder);
    }
  }
}

.ant-input-affix-wrapper,
.ant-input-number {
  background: transparent;
  border-color: var(--color-inp-border-default);
}

.ant-input-number {
  .ant-input-number-input {
    color: var(--color-txt-primary);
  }
}

.ant-card {
  background: transparent;

  .ant-card-head {
    border-color: var(--color-brdr-default);
  }

}

.ant-dropdown, .ant-dropdown-menu-submenu {
  .ant-dropdown-menu {
    background: var(--color-srf-menu);
  }
}

.ant-dropdown,
.ant-dropdown-menu-submenu {
  .ant-dropdown-menu {
    .ant-dropdown-menu-item,
    .ant-dropdown-menu-submenu-title {
      color: var(--color-txt-primary);
    }

    .ant-dropdown-menu-item-disabled,
    .ant-dropdown-menu-submenu-title-disabled {
      pointer-events: none;
      color: var(--color-txt-secondary);
    }
  }
}


.ant-select-dropdown {
  color: var(--color-txt-primary);
  background: var(--color-srf-menu);

  .ant-select-item-option-selected, .ant-select-item-option-active {
    &:not(.ant-select-item-option-disabled) {
      background: var(--color-srf-menu-hover);
    }
  }

}

.ant-select-focused.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) {
  .ant-select-selector {
    border-color: var(--color-brdr-accent);
  }
}

.ant-tabs {
  color: var(--color-txt-primary);

  .ant-tabs-ink-bar {
    background: var(--color-txt-active);;
  }

  .ant-tabs-tab {
    .ant-tabs-tab-btn {
      //text-transform: none;
      color: var(--color-txt-secondary);
    }

    &.ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        color: var(--color-txt-active);
        text-shadow: none;
      }
    }
  }
}

.ant-collapse {
  background: var(--color-srf-primary);
  border: 1px solid var(--color-brdr-default);

  & > .ant-collapse-item {
    border-bottom: none;

    & >.ant-collapse-header {
      color: var(--color-txt-primary);
    }

  }

  .ant-collapse-content {
    border-top-color: var(--color-brdr-default);
    //background: var(--color-bg-primary);
    background: transparent;
    color: var(--color-txt-primary);
  }

}

.ant-progress {
  .ant-progress-inner {
    background: var(--color-srf-secondary);
  }

  .ant-progress-success-bg, .ant-progress-bg {
    background: var(--color-srf-accent);
  }

}

.ant-popover {
  //background: var(--color-srf-menu);

  .ant-popover-inner {
    background: var(--color-srf-menu)!important;
  }

  .ant-popover-arrow {
    &:before {
      background: var(--color-srf-menu);
    }
  }
}

.ant-typography {
  &.ant-typography-secondary {
    color: var(--color-txt-secondary);
  }
}

.ant-table-wrapper {
  .ant-table-thead {
    >tr {
      th, td {
        color: var(--color-txt-primary);

        &:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]) {
          &::before {
            background: var(--color-brdr-default)!important;
          }
        }

      }
    }
  }

  .ant-table-tbody {
    > tr {
      > td {
        border-bottom-color: var(--color-brdr-light);
      }
    }
  }

  .ant-table {
    &.ant-table-bordered {
      >.ant-table-container {
        border: 1px solid var(--color-brdr-light);

        >.ant-table-content, >.ant-table-header, >.ant-table-body, >.ant-table-summary {
          >table {
            >thead, >tbody, >tfoot {
              >tr {
                >th, >td {
                  border-inline-end-color: transparent;
                  border-bottom-color: var(--color-brdr-light);
                }
              }
            }
          }
        }
      }
    }

    .ant-table-thead {
      >tr {
        >td, >th {
          background: var(--color-srf-semisecondary);
        }
      }
    }
  }
}


a.ant-typography:focus,
.ant-typography a:focus,
a.ant-typography:hover,
.ant-typography a:hover {
  color: var(--color-txt-link-hover);
}

.ant-popover {
  z-index: 2000;
}

.ant-select-dropdown {
  z-index: 2001!important;
}

.ant-drawer {
  .ant-drawer-content {
    background: var(--color-srf-primary);
  }
}
